















































































































































import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import GoogleSignInButton from "vue-google-signin-button-directive";
import MarketingLogo from "@/components/marketing/MarketingLogo.vue";
import { UserVerifyRequest } from "../../application/contracts/master/users/UserVerifyRequest";

@Component({
  components: { MarketingLogo },
  directives: {
    GoogleSignInButton,
  },
})
export default class VerifyComponent extends BaseComponent {
  public requireCompany: boolean = false;
  public requireName: boolean = false;
  public requirePassword: boolean = true;
  public droppedDown: boolean = false;
  public user = {} as UserVerifyRequest;
  private created() {
    this.user.email = this.$route.query.e ? this.$route.query.e.toString() : "";
    this.user.token = this.$route.query.t ? this.$route.query.t.toString() : "";
    // console.log(this.user);
  }
  private verify() {
    if (this.user.password !== this.user.passwordConfirm) {
      // @ts-ignore
      this.$refs["error-modal"].show(
        this.$t("account.login.errors.passwordMissmatch")
      );
      return;
    }
    // @ts-ignore
    this.$refs.loadingButton.start();
    this.services.authentication
      .verify(this.user)
      .catch((error) => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      })
      .finally(() => {
        // @ts-ignore
        this.$refs.loadingButton.stop();
      });
  }
  private closeDropdown() {
    this.droppedDown = false;
  }
}
