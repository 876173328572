










































































































































import Component from "vue-class-component";
import BaseComponent from "../../../../components/shared/BaseComponent.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import Modal from "@/components/shared/modals/Modal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import Plans from "@/components/marketing/Plans.vue";
import { Card, createToken } from "vue-stripe-elements-plus";
import CurrencyToggle from "@/components/marketing/toggles/CurrencyToggle.vue";
import { SubscriptionProductDto } from "../../../../application/dtos/master/subscriptions/SubscriptionProductDto";
import { SubscriptionBillingPeriod } from "../../../../application/enum/master/SubscriptionBillingPeriod";
import { SubscriptionPriceDto } from "../../../../application/dtos/master/subscriptions/SubscriptionPriceDto";
import { TenantCreateRequest } from "../../../../application/contracts/master/tenants/TenantCreateRequest";

@Component({
  components: {
    SuccessModal,
    ErrorModal,
    Modal,
    ConfirmModal,
    Plans,
    Card,
    CurrencyToggle,
  },
})
export default class TenantNew extends BaseComponent {
  private name: string = "";
  private stripeKey: string = "";
  private stripeOptions: any = {};
  private complete: boolean = false;
  created() {
    // @ts-ignore
    this.stripeKey = process.env.VUE_APP_SUBSCRIPTION_PUBLIC_KEY.toString();
    this.stripeOptions = {
      showCardHolderName: true,
      hidePostalCode: false,
      iconStyle: "solid",
      elements: {
        locale: this.$i18n.locale,
      },
    };
    if (!this.selectedProduct) {
      this.$store.commit("pricing/select", {
        product: this.$store.state.pricing.products[0],
        billingPeriod: SubscriptionBillingPeriod.Monthly,
      });
    }
  }
  close() {
    this.$emit("close");
  }
  createTenant() {
    if (this.name !== "") {
      // @ts-ignore
      this.$refs["confirm-modal"].show(
        this.$t("settings.tenant.createConfirm")
      );
    }
  }
  tryRegister() {
    // if (!this.selectedPrice || !this.selectedPrice.id) {
    //   // @ts-ignore
    //   this.$refs["error-modal"].show("Select a product price");
    //   return;
    // }
    if (this.selectedPrice?.trialDays === 0 && this.selectedPrice?.price > 0) {
      // @ts-ignore
      this.$refs["card-modal"].show(
        this.$t("account.register.providePaymentDetails"),
        this.$t("shared.cancel")
      );
    } else {
      this.register();
    }
  }
  pay() {
    createToken()
      .then((data) => {
        if (data.error) {
          // @ts-ignore
          this.$refs["error-modal"].show(data.error.message);
        } else {
          this.register(data.token.id);
        }
      })
      .catch((error) => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      })
      .finally(() => {
        // @ts-ignore
        this.$refs["card-modal"].close();
      });
  }
  register(stripeCardToken: string = "") {
    const tenantCreateRequest: TenantCreateRequest = {
      name: this.name,
      selectedSubscription: {
        subscriptionPriceId: this.selectedPrice?.id ?? "",
        subscriptionCardToken: stripeCardToken,
        subscriptionCoupon: "",
      },
    };
    this.services.tenants
      .create(tenantCreateRequest)
      .then((response) => {
        // // @ts-ignore
        // this.$refs["success-modal"].show(this.$t("settings.tenant.created"));
        this.name = "";
        this.$router.go(0);
      })
      .catch((error) => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      });
  }
  get selectedProduct() {
    return this.$store.state.pricing.selectedProduct as SubscriptionProductDto;
  }
  get getButtonText() {
    if (this.selectedPrice) {
      return (
        (this.selectedPrice.billingPeriod === SubscriptionBillingPeriod.Once
          ? this.$t("marketing.pricing.pay")
          : this.$t("marketing.pricing.subscribe")) +
        " " +
        this.priceDescription
      );
    }
  }
  get selectedPrice(): SubscriptionPriceDto | undefined {
    if (this.selectedProduct) {
      return (
        this.selectedProduct.prices.find(
          (f) => f.billingPeriod === this.$store.state.pricing.billingPeriod
        ) ?? this.selectedProduct.prices[0]
      );
    }
  }
  get billingPeriod() {
    if (this.selectedPrice) {
      if (
        this.selectedPrice?.billingPeriod === SubscriptionBillingPeriod.Once
      ) {
        return this.$t("marketing.pricing.once");
      } else {
        return (
          "/ " +
          this.$t(
            "marketing.pricing." +
              SubscriptionBillingPeriod[this.selectedPrice.billingPeriod] +
              "Short"
          )
        );
      }
    }
  }
  get priceDescription() {
    return "$" + this.selectedPrice?.price + " " + this.billingPeriod;
  }
}
