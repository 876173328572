































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import {
    mapGetters
} from "vuex";
import {
    defaultProducts
} from "../../../store/modules/pricing/default-pricing";
import {
    defaultProductsDevelopment
} from "../../../store/modules/pricing/default-pricing.Development";
import {
    SubscriptionProductDto
} from "../../../application/dtos/master/subscriptions/SubscriptionProductDto";
import {
    SubscriptionGetCurrentResponse
} from "../../../application/contracts/master/subscriptions/SubscriptionGetCurrentResponse";
import {
    TenantUserRole
} from "../../../application/enum/master/TenantUserRole";
import { TenantProductDto } from "../../../application/dtos/master/tenants/TenantProductDto";

@Component({
    components: {},
    computed: {
        ...mapGetters("tenant", {
            activeProduct: "activeProduct",
            subscription: "subscription",
            role: "role",
            roleName: "roleName",
        }),
    },
})
export default class DemoActionsComponent extends BaseComponent {
    public activeProduct!: TenantProductDto;
    public role!: TenantUserRole;
    public roleName!: string;
    public subscription!: SubscriptionGetCurrentResponse;
    public products: SubscriptionProductDto[] = [];
    public roleKeys: string[] = [];
    public roleValues: string[] = [];
    mounted() {
        this.roleKeys = Object.keys(TenantUserRole).filter(
            (k) => typeof TenantUserRole[k as any] === "number"
        );
        this.roleValues = this.roleKeys.map((k) => TenantUserRole[k as any]); // [0, 1]
        if (process.env.NODE_ENV !== "production") {
            this.products = defaultProductsDevelopment;
        } else {
            this.products = defaultProducts;
        }
    }
    everyone() {
        this.showAllowed();
    }
    //   onlyTier1() {
    //     if (
    //       this.activeProduct.tier >= 1 &&
    //       (!this.subscription || this.subscription.status !== "trialing")
    //     ) {
    //       this.showAllowed();
    //     } else {
    //       this.showNotAllowed();
    //     }
    //   }
    onlyTierAndUp(product: SubscriptionProductDto) {
        if (this.activeProduct.subscriptionProduct.tier >= product.tier) {
            this.showAllowed();
        } else {
            this.showNotAllowed();
        }
    }
    getRoleName(role) {
        return TenantUserRole[role];
    }
    onlyRole(role: TenantUserRole) {
        console.log("current: " + this.role);
        console.log("role: " + role);
        if (this.role > role) {
            // @ts-ignore
            this.$refs["error-modal"].show(
                this.$t("account.tenant.only" + TenantUserRole[role])
            );
        } else {
            this.showAllowed();
        }
    }
    showAllowed() {
        // @ts-ignore
        this.$refs["success-modal"].show(this.$t("shared.allowed"));
    }
    showNotAllowed() {
        // @ts-ignore
        this.$refs["confirm-modal"].show(this.$t("shared.upgrade"));
    }
    yesUpdateSubscription() {
        this.$router.push({
            path: "/app/settings/organization/subscription"
        });
    }
}
