<template>
  <div>
    <router-view></router-view>
    <p class="text-center text-gray-500 text-xs py-8">
      {{ $t("marketing.footer.copyright") }}
    </p>
  </div>
</template>

<script>
export default {};
</script>
