
















































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { mapGetters } from "vuex";
import TenantNew from "@/components/app/settings/tenant/TenantNew.vue";
import TenantSubscriptionStatus from "@/components/app/settings/tenant/subscription/TenantSubscriptionStatus.vue";
import Breadcrumb from "./Breadcrumb.vue";
import { SignalService } from "../../plugins/SignalR";
import { Prop } from "vue-property-decorator";
import routes from "../../router/routes";
import { RouteConfig } from "vue-router";
import { JsonHubProtocol } from "@aspnet/signalr";
import { SideBarItem } from "../../application/sidebar/SidebarItem";
import { adminSidebar } from "../../application/sidebar/adminSidebar";
import { appSidebar } from "../../application/sidebar/appSidebar";
import services from "../../services";
import { TenantDto } from "../../application/dtos/master/tenants/TenantDto";
import { SubscriptionProductDto } from "../../application/dtos/master/subscriptions/SubscriptionProductDto";
import { UserType } from "../../application/enum/master/UserType";

@Component({
  components: { TenantNew, TenantSubscriptionStatus, Breadcrumb },
  computed: {
    ...mapGetters("account", {
      profileName: "profileName",
      avatarText: "avatarText",
      avatar: "avatar",
      isAdmin: "isAdmin",
    }),
    ...mapGetters("theme", {
      appTheme: "appTheme",
      appColor: "appColor",
    }),
    ...mapGetters("tenant", {
      activeProduct: "activeProduct",
      roleName: "roleName",
    }),
  },
})
export default class AppLayoutComponent extends Vue {
  @Prop()
  public layout!: string;
  private adminTheme: string = "";
  private sidebarOpen: boolean = false;
  private dropDownTenantMobile: boolean = false;
  private dropDownTenantDesktop: boolean = false;
  private dropDownUser: boolean = false;
  private menu: SideBarItem[] = [];
  private expanded: number[] = [];
  private addingTenant: boolean = false;
  private appTheme!: string;
  private appColor!: string;
  mounted() {
    if (this.layout === "admin") {
      this.menu = adminSidebar;
      this.$store.commit("theme/appTheme", "theme-bg-dark");
      this.$store.commit("theme/appColor", "theme-indigo");
    } else {
      this.menu = appSidebar;
      this.$store.commit("theme/appTheme", this.$store.state.tenant.current.appTheme);
      this.$store.commit("theme/appColor", this.$store.state.tenant.current.appColor);
    }
    this.menu.forEach((group) => {
      group.items?.forEach((element, index) => {
        if (element.open) {
          this.expanded.push(index);
        } else {
          this.expanded = this.expanded.filter((f) => f !== index);
        }
      });
    });
    this.showConversations();

    services.tenants.getAll();

    SignalService.on("UserConnected", (message) => {
      // alert(message);
    });
  }
  showConversations() {
    // @ts-ignore
    if (this.$intercom) {
      // @ts-ignore
      // this.$intercom.boot({
      //   user_id: this.$store.state.account.user.id,
      //   name: this.$store.state.account.user.firstName,
      //   email: this.$store.state.account.user.email,
      // });
      // @ts-ignore
      // this.$intercom.show();
    }
    // @ts-ignore
    if (this.$drift) {
      // @ts-ignore
      // this.$drift.identify(this.$store.state.account.user.id, {
      //   name: this.$store.state.account.user.firstName,
      //   email: this.$store.state.account.user.email,
      // });
      // @ts-ignore
      // this.$drift.show();
    }
  }
  logout() {
    this.$store.dispatch("auth/logout");
  }
  changeTenant(tenant: TenantDto) {
    this.closeDropdownTenant();
    services.users
      .updateDefaultTenant(tenant.id)
      .then((response: TenantDto) => {
        this.$store.commit("tenant/current", response);
        this.$router.go(0);
      });
  }
  closeDropdownUser() {
    this.dropDownUser = false;
  }
  closeSidebar() {
    if (this.sidebarOpen) {
      this.sidebarOpen = false;
    }
  }
  closeDropdownTenant() {
    this.dropDownTenantMobile = false;
  }
  closeDropdownTenantDesktop() {
    this.dropDownTenantDesktop = false;
  }
  toggleMenuItem(index) {
    if (this.expanded.includes(index)) {
      this.expanded = this.expanded.filter((item) => item !== index);
    } else {
      this.expanded.push(index);
    }
  }
  menuItemIsExpanded(index: number) {
    return this.expanded.includes(index);
  }
  translate(title) {
    const translated = this.$t(title.trim());
    return translated;
  }
  addTenant() {
    this.sidebarOpen = false;
    this.closeDropdownTenantDesktop();
    this.addingTenant = true;
  }
  createdTenant() {
    this.addingTenant = false;
  }

  addPricesToAdminSidebar(prices: SideBarItem) {
    prices.items = [];
    if (prices && this.products) {
      this.products.forEach((element: SubscriptionProductDto) => {
        const price: SideBarItem = {
          title: element.title,
          path: "/admin/products/" + element.id,
        };
        if (element.id && price) {
          prices.items?.push(price);
        }
      });
      if (prices.items.length > 0) {
        prices.items.unshift({
          title: this.$t("shared.all"),
          path: "/admin/products",
        });
        prices.open = true;
      }
    }
  }
  addToBreadcrumb(items: any, route: RouteConfig) {
    if (route.path !== this.$route.path) {
      if (!items.some((f) => f.link === route.path)) {
        items.push({
          name: route.meta?.title ?? "Untitled",
          link: route.path,
        });
      }
    }
  }
  getPlanFromTenant(tenant: TenantDto): string {
    // console.log("getPlanFromTenant: " + JSON.stringify(tenant));
    if (
      tenant.products &&
      tenant.products.length > 0 &&
      tenant.products[0].subscriptionProduct
    ) {
      return tenant.products[0].subscriptionProduct.title;
    } else {
      if (tenant.subdomain === "admin") {
        return "Admin";
      } else {
        return this.$t("settings.tenant.subscription.notSubscribed").toString();
      }
    }
  }
  get products(): SubscriptionProductDto[] {
    return this.$store.state.pricing.products as SubscriptionProductDto[];
  }
  get myTenants(): TenantDto[] {
    return this.$store.state.tenant.tenants;
  }
  get currentTenant(): TenantDto {
    return this.$store.state.tenant.current ?? { name: "Undefinded" };
  }
  get currentUserType(): UserType {
    return this.$store.state.account.user.type as UserType;
  }
  get bgPrimary() {
    if (this.$store.state.theme.appTheme === "theme-bg-dynamic") {
      return "bg-theme-900 text-theme-100";
    } else {
      return "bg-primary";
    }
  }
  get getMenu() {
    if (this.layout === "admin" && this.menu.length > 0 && this.menu[0].items) {
      const prices: SideBarItem | undefined = this.menu[0].items.find(
        (f) => f.path === "/admin/products"
      );
      if (prices) {
        prices.items = [];
        this.addPricesToAdminSidebar(prices);
      }
    }
    const menu = this.menu.filter(
      (f) =>
        (!f.userTypes || f.userTypes.includes(this.currentUserType)) &&
        f.items?.find(
          (x) => !x.userTypes || x.userTypes.includes(this.currentUserType)
        )
    );
    return menu;
  }
}
