

































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import Charts from "@/views/app/integrations/Charts.vue";
import SvgDashboard from "../../../assets/img/dashboard.vue";

@Component({
  components: { Charts, SvgDashboard },
})
export default class DashboardComplement extends BaseComponent {}
