































































































import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import GoogleSignInButton from "vue-google-signin-button-directive";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import MarketingLogo from "@/components/marketing/MarketingLogo.vue";
import { UserVerifyRequest } from "../../application/contracts/master/users/UserVerifyRequest";

@Component({
  components: { ErrorModal, MarketingLogo },
  directives: {
    GoogleSignInButton,
  },
})
export default class VerifyComponent extends BaseComponent {
  public droppedDown: boolean = false;
  public user = {} as UserVerifyRequest;
  private created() {
    this.user.email = this.$route.query.e ? this.$route.query.e.toString() : "";
    this.user.token = this.$route.query.t ? this.$route.query.t.toString() : "";
    // console.log(this.user);
  }
  private reset() {
    this.loading = true;
    if (this.user.password !== this.user.passwordConfirm) {
      return;
    }
    this.services.authentication.verify(this.user).catch((error) => {
      // @ts-ignore
      this.$refs["error-modal"].show(error);
    });
  }
  private closeDropdown() {
    this.droppedDown = false;
  }
}
