













































import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";

@Component({
  components: { SuccessModal, ErrorModal },
})
export default class FaqComponent extends BaseComponent {
  questions: any = [
    { question: "Qual a performance?", answer: "Não temos como passar essa informação, a performance vai depender das configurações que cada usuário utiliza dentro do robô." },
    { question: "Roda em qual plataforma?", answer: "Apenas em MetaTrader 5." },
    { question: "Roda em quais corretoras?", answer: "Todas que forneçam a plataforma MetaTrader 5." },
    { question: "Roda em quais ativos?", answer: "Qualquer ativo." },
    { question: "Roda em contas hedging?", answer: "Sim." },
    { question: "Posso rodar vários setups?", answer: "Sim." },
    // { question: "Preciso assinar um plano?", answer: "Não, oferecemos uma versão gratuíta que pode ser utiliza em conta real com algumas limitações. O plano só é necessário caso você precise utilizar todos os recursos do robô." },
    { question: "Onde encontro o download?", answer: "Buscando por 'Santa Trader' na loja do MetaTrader ou acessando diretamente o link: https://www.mql5.com/pt/market/product/63796" },
  ];
  expanded: any = [];
  mounted() {
    this.expanded.push(this.questions[0].question);
  }
  toggle(question) {
    if (this.expanded.includes(question.question)) {
      this.expanded = this.expanded.filter(
        (item) => item !== question.question
      );
    } else {
      this.expanded.push(question.question);
    }
  }
  isExpanded(question) {
    return this.expanded.includes(question.question);
  }
}
