




















































































































import Component from "vue-class-component";
import BaseComponent from "../../../../../components/shared/BaseComponent.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";

import TenantSubscriptionPlan from "@/components/app/settings/tenant/subscription/TenantSubscriptionPlan.vue";
import TenantSubscriptionProducts from "@/components/app/settings/tenant/subscription/TenantSubscriptionProducts.vue";
import TenantSubscriptionPaymentDetails from "@/components/app/settings/tenant/subscription/TenantSubscriptionPaymentDetails.vue";
import TenantSubscriptionInvoices from "@/components/app/settings/tenant/subscription/TenantSubscriptionInvoices.vue";
import { mapGetters } from "vuex";
import { SubscriptionBillingPeriod } from "../../../../../application/enum/master/SubscriptionBillingPeriod";
import { SubscriptionPriceDto } from "../../../../../application/dtos/master/subscriptions/SubscriptionPriceDto";
import { TenantProductDto } from "../../../../../application/dtos/master/tenants/TenantProductDto";

@Component({
  components: {
    ConfirmModal,
    SuccessModal,
    ErrorModal,
    TenantSubscriptionPlan,
    TenantSubscriptionProducts,
    TenantSubscriptionPaymentDetails,
    TenantSubscriptionInvoices,
  },
  computed: {
    ...mapGetters("tenant", {
      activeProduct: "activeProduct",
    }),
  },
})
export default class TenantSubscription extends BaseComponent {
  mounted() {
    this.services.subscriptionProducts.getProducts(true, true);
  }
  cancel() {
    // @ts-ignore
    this.$refs["confirm-modal"].show(
      this.$t("settings.tenant.subscription.confirmCancel")
    );
  }
  confirmCancel() {
    return this.services.subscriptionManager
      .cancelSubscription()
      .then((response) => {
        // @ts-ignore
        this.$refs["success-modal"].show(
          this.$t("settings.tenant.subscription.canceled")
        );
      })
      .catch((error) => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      });
  }
  get products(): TenantProductDto[] | undefined {
    return this.$store.state.tenant.subscription
      .myProducts as TenantProductDto[];
  }
  get subscription(): SubscriptionPriceDto | undefined {
    const mySubscriptions = this.products?.filter(
      (f) =>
        f.subscriptionPrice.billingPeriod !== SubscriptionBillingPeriod.Once
    );
    if (mySubscriptions && mySubscriptions.length > 0) {
      return mySubscriptions[0].subscriptionPrice;
    } else {
      return undefined;
    }
  }
}
