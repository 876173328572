



















import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import Header from "@/components/marketing/Header.vue";
import Products from "../../components/marketing/pricing/Products.vue";
import TableRowSkeleton from "@/components/shared/skeletons/TableRowSkeleton.vue";
import Footer from "@/components/marketing/Footer.vue";

@Component({
  components: {
    Header,
    Products,
    TableRowSkeleton,
    Footer,
  },
})
export default class PricingComponent extends BaseComponent {
  mounted() {
    // @ts-ignore
    // if (this.$intercom) {
    //   // @ts-ignore
    //   // this.$intercom.show();
    // }
  }
}
