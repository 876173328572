









































import Component from "vue-class-component";
import { SubscriptionPriceDto } from "../../../application/dtos/master/subscriptions/SubscriptionPriceDto";
import { SubscriptionProductDto } from "../../../application/dtos/master/subscriptions/SubscriptionProductDto";
import { SubscriptionBillingPeriod } from "../../../application/enum/master/SubscriptionBillingPeriod";
import BaseComponent from "../../../components/shared/BaseComponent.vue";

@Component
export default class BillingPeriodToggleComponent extends BaseComponent {
  private changeInterval(billingPeriod: SubscriptionBillingPeriod) {
    this.$store.commit("pricing/billingPeriod", billingPeriod);
  }
  private changeToMonthly() {
    this.changeInterval(SubscriptionBillingPeriod.Monthly);
  }
  private changeToYearly() {
    this.changeInterval(SubscriptionBillingPeriod.Yearly);
  }
  private billingPeriodName(billingPeriod: SubscriptionBillingPeriod): string {
    return SubscriptionBillingPeriod[billingPeriod];
  }
  private isYearly(billingPeriod: SubscriptionBillingPeriod): boolean {
    return billingPeriod === SubscriptionBillingPeriod.Yearly;
  }
  private yearlyDiscount(
    billingPeriod: SubscriptionBillingPeriod
  ): string | undefined {
    const priceYearly = this.getPriceWithInterval(
      SubscriptionBillingPeriod.Yearly
    );
    const priceMonthly = this.getPriceWithInterval(
      SubscriptionBillingPeriod.Monthly
    );
    if (priceYearly && priceMonthly) {
      const discount =
        100 - (priceYearly.price * 100) / (priceMonthly.price * 12);
      if (discount !== 0) {
        return "-" + discount.toFixed(0) + "%";
      }
    }

    return undefined;
  }
  private getPriceWithInterval(
    billingPeriod: SubscriptionBillingPeriod
  ): SubscriptionPriceDto | undefined {
    let price: SubscriptionPriceDto | undefined;
    if (this.products && this.products.length > 0) {
      this.products.forEach((product) => {
        const prices = product.prices.find(
          (f) =>
            f.billingPeriod === billingPeriod &&
            f.currency === this.$store.state.pricing.currency
        );
        if (prices) {
          price = prices;
        }
      });
    }
    return price;
  }
  get products(): SubscriptionProductDto[] {
    return this.$store.state.pricing.products as SubscriptionProductDto[];
  }
  get billingPeriods() {
    const allBillingPeriods: SubscriptionBillingPeriod[] = [];
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    this.products.forEach((element) => {
      element.prices.forEach((price) => {
        if (
          this.$store.state.pricing.currency === price.currency &&
          price.billingPeriod !== SubscriptionBillingPeriod.Once
        ) {
          allBillingPeriods.push(price.billingPeriod);
        }
      });
    });
    return allBillingPeriods.filter(onlyUnique);
  }
  get billingPeriod() {
    return this.$store.state.pricing.billingPeriod as SubscriptionBillingPeriod;
  }
  get isMonthly() {
    return this.billingPeriod !== SubscriptionBillingPeriod.Monthly;
  }
}
