


















import Vue from "vue";
import DoughnutChart from "@/plugins/charts/DoughnutChart";
import Component from "vue-class-component";

@Component({ components: { DoughnutChart } })
export default class DoughnutChartComponent extends Vue {
  datacollection: any = {};

  mounted() {
    this.fillData();
  }

  fillData() {
    this.datacollection = {
      labels: [this.getRandomInt(), this.getRandomInt()],
      datasets: [
        {
          label: "Data One",
          backgroundColor: "#9AE6B4",
          data: [this.getRandomInt(), this.getRandomInt()],
        },
        {
          label: "Data Two",
          backgroundColor: "#81E6D9",
          data: [this.getRandomInt(), this.getRandomInt()],
        },
      ],
    };
  }
  getRandomInt() {
    return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
  }
}
