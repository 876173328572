


















































































































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import PageTitle from "@/components/shared/navigation/PageTitle.vue";
import EntityTableList from "@/components/shared/entities/EntityTableList.vue";
import EntityForm from "@/components/shared/entities/EntityForm.vue";
import { ColumnType, ValueType } from "../../../application/dtos/ColumnType";
import { TransactionDto } from "../../../application/dtos/app/transactions/TransactionDto";
import SvgTransactionsEmpty from "../../../assets/img/transactionsEmpty.vue";

@Component({
  components: { PageTitle, EntityTableList, EntityForm, SvgTransactionsEmpty },
})
export default class TransactionListComponent extends BaseComponent {
  private modelName: string = "transaction";
  private columns: ColumnType[] = [
    { name: "description" },
    { name: "quantity", valueType: ValueType.Number },
    { name: "price", valueType: ValueType.Number },
    { name: "amount", valueType: ValueType.Number, cannotEdit: true },
    { name: "date", valueType: ValueType.Date },
    { name: "category" },
  ];
  private selected = null as any | null;
  private adding: boolean = false;
  private items: any = [];
  mounted() {
    this.items = [];
    this.reload();
  }
  reload() {
    this.loading = true;
    this.services.transactions
      .getAll()
      .then((response: TransactionDto[]) => {
        this.items = response;
      })
      .catch((error) => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  closeForm(hasChanges = false) {
    this.selected = null;
    this.adding = false;
    if (hasChanges) {
      // @ts-ignore
      this.$parent.hasChanges = true;
    }
  }
  edit(item: any) {
    this.selected = item;
  }
  added(data: TransactionDto) {
    console.log("added");
    this.services.transactions
      .create(data)
      .then((response) => {
        // @ts-ignore
        this.items.push(response);
        this.selected = null;
        this.closeForm(true);
      })
      .catch((error) => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      });
  }
  saved(data: TransactionDto) {
    console.log("saved");
    this.services.transactions
      .update(data.id, data)
      .then((response) => {
        // @ts-ignore
        const idx = this.items.findIndex((f) => f.id === data.id);
        console.log(`updating idx ${idx}: id${data.id}`);
        this.items[idx] = response;
        this.closeForm(true);
      })
      .catch((error) => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      });
  }
  deleted(data: TransactionDto) {
    console.log("deleted");
    this.services.transactions
      .delete(data.id)
      .then((response) => {
        // @ts-ignore
        this.items = this.items.filter((f) => f.id !== data.id);
        this.closeForm(true);
      })
      .catch((error) => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      });
  }
  canceled() {
    console.log("canceled");
    this.closeForm();
  }
}
