







































































































































import Vue from "vue";
import { VueTyper } from "vue-typer";
import Component from "vue-class-component";
import SvgLogo from "../../assets/img/cover.vue";

@Component({ components: { VueTyper, SvgLogo } })
export default class HeroComponent extends Vue {
  private email: string = "";
  private imgCoverLight: string = "https://i.ibb.co/wc80JMc/3.png";
  private imgCoverDark: string = "https://i.ibb.co/0G38BLx/2.png";
  register() {
    this.$router.push({ path: "pricing?e=" + this.email });
  }
}
