







































































import Component from "vue-class-component";
import BaseComponent from "../../../../../components/shared/BaseComponent.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import { SubscriptionInvoiceDto } from "../../../../../application/dtos/master/subscriptions/SubscriptionInvoiceDto";

@Component({
  components: { ErrorModal, SuccessModal },
})
export default class TenantSubscriptionInvoices extends BaseComponent {
  private upcomingInvoice = {} as SubscriptionInvoiceDto;
  created() {
    const self = this;
    this.eventBus.$on("updated-plan", () => self.getUpcomingInvoice());
  }
  beforeDestroy() {
    this.eventBus.$off("updatedPlan");
  }
  mounted() {
    this.getUpcomingInvoice();
  }
  getUpcomingInvoice() {
    const self = this;
    this.services.subscriptionManager
      .getUpcomingInvoice()
      .then((response: SubscriptionInvoiceDto) => {
        self.upcomingInvoice = response;
      });
  }
  invoiceDescription(invoice: SubscriptionInvoiceDto) {
    if (invoice && invoice.created) {
      console.log("created: " + invoice.created);
      const date = new Date(invoice.created);
      return date.toDateString();
    }
    return "";
  }
}
