



























































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import { SignalService } from "../../../plugins/SignalR";
import * as signalR from "@aspnet/signalr";

@Component({
  components: { SuccessModal, ErrorModal, ConfirmModal },
})
export default class SignalR extends BaseComponent {
  userName: string = this.$store.state.account.user.email;
  message: string = "";
  messages: object[] = [];
  connection!: signalR.HubConnection;
  hubUrl!: string;
  created() {
    this.hubUrl = process.env.VUE_APP_SERVER_URL + "globalHub";
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(this.hubUrl)
      .configureLogging(signalR.LogLevel.Information)
      .build();
    this.connection
      .start()
      .then((response) => {
        this.joinGroup();
        return true;
      })
      .catch((err) => {
        return console.error(
          "Could not connect to Hub '" + this.hubUrl + "':" + err.toString()
        );
      });
  }
  mounted() {
    const self = this;
    this.connection.on("ReceiveMessage", (user, message) => {
      self.messages.push({ user, message });
    });
  }
  async joinGroup() {
    this.connection
      .invoke("JoinGroup", this.$store.state.tenant.current?.id)
      .then((response) => {
        console.log("JoinGroup Success:" + JSON.stringify(response));
      })
      .catch((err) => {
        console.log("JoinGroup Error:" + JSON.stringify(err));
      });
  }
  submitCard() {
    if (this.message) {
      this.connection
        .invoke(
          "SendMessage",
          this.$store.state.tenant.current.id,
          this.userName,
          this.message
        )
        .then((response) => {
          this.message = "";
          // @ts-ignore
          this.$refs.message.focus();
        })
        .catch((err) => {
          return console.error(err.toString());
        });
    }
  }
}
