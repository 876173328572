




















import Component from "vue-class-component";
import BaseComponent from "@/components/shared/BaseComponent.vue";

import LineChart from "@/components/app/integrations/charts/LineChart.vue";
import BarChart from "@/components/app/integrations/charts/BarChart.vue";
import DoughnutChart from "@/components/app/integrations/charts/DoughnutChart.vue";

@Component({
  components: { LineChart, BarChart, DoughnutChart },
})
export default class ChartsComponent extends BaseComponent {}
