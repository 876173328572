const supportedLocales = [{
        lang: "en-US",
        flag: "us",
        lang_short: "en"
    },
    {
        lang: "pt-BR",
        flag: "br",
        lang_short: "pt"
    }    
    // {
    //     lang: "es-MX",
    //     flag: "mx",
    //     lang_short: "es"
    // }, {
    //     lang: "hi-IN",
    //     flag: "in",
    //     lang_short: "hi"
    // }
]
export default supportedLocales;