
















































































































































import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import LoadingButton from "@/components/shared/buttons/LoadingButton.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import MarketingLogo from "@/components/marketing/MarketingLogo.vue";
import { mapGetters } from "vuex";
import { defaultState } from "../../store/modules/theme";
import { UserVerifyRequest } from "../../application/contracts/master/users/UserVerifyRequest";
import { TenantDto } from "../../application/dtos/master/tenants/TenantDto";
import { TenantUserDto } from "../../application/dtos/master/tenants/TenantUserDto";

@Component({
  components: { ErrorModal, MarketingLogo, LoadingButton },
  computed: {
    ...mapGetters("theme", {
      marketingTheme: "marketingTheme",
      marketingColor: "marketingColor",
    }),
    ...mapGetters("account", {
      email: "email",
    }),
  },
})
export default class InviteURLComponent extends BaseComponent {
  public requirePhone: boolean = true;
  public user = {} as UserVerifyRequest;
  public emailDisabled: boolean = false;
  public tenant = {} as TenantDto;
  public email!: string;
  private requested: boolean = false;
  private created() {
    this.user.token = this.$route.params.linkUrl ?? "";
    this.user.email = this.email;
  }
  private mounted() {
    this.user.email = "";
    if (this.email && this.email !== "") {
      this.emailDisabled = true;
      this.user.email = this.email;
    }
    if (this.$route.params.linkUrl) {
      this.loading = true;
      this.$store.commit("theme/marketingTheme", defaultState.marketingTheme);
      this.$store.commit("theme/marketingColor", defaultState.marketingColor);
      this.services.tenantUserInvitations
        .getInviteURL(this.$route.params.linkUrl)
        .then((response: TenantDto) => {
          this.tenant = response;

          if (this.tenant.appTheme) {
            this.$store.commit("theme/marketingTheme", this.tenant.appTheme);
          }
          if (this.tenant.appColor) {
            this.$store.commit("theme/marketingColor", this.tenant.appColor);
          }
        })
        .catch((error) => {
          if (error.status === 404) {
            // @ts-ignore
            this.$refs["error-modal"].show(this.$t("shared.invalidInvitation"));
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  private accept() {
    if (this.user.password !== this.user.passwordConfirm) {
      // @ts-ignore
      this.$refs["error-modal"].show(
        this.$t("account.login.errors.passwordMissmatch")
      );
      return;
    }
    // @ts-ignore
    this.$refs.loadingButton.start();
    this.services.tenantUserInvitations
      .requestAccess(this.$route.params.linkUrl, this.user)
      .then((response: TenantUserDto) => {
        this.requested = true;
      })
      .catch((error) => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      })
      .finally(() => {
        // @ts-ignore

        this.$refs.loadingButton.stop();
      });
  }
  private logout() {
    this.$store.commit("auth/logout");
    this.emailDisabled = false;
    this.email = "";
    this.user.email = "";
  }
  get tenantTheme() {
    if (this.tenant) {
      return this.tenant.appTheme + " " + this.tenant.appColor;
    }
  }
}
