




























































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import Selector from "@/components/shared/forms/Selector.vue";
import { mapGetters } from "vuex";
import DarkModeToggle from "./selectors/DarkModeToggle.vue";
import ColorSwitcher from "./selectors/ColorSwitcher.vue";
import FlagSelector from "./selectors/FlagSelector.vue";
import { Watch } from "vue-property-decorator";

@Component({
  components: {
    Selector,
    DarkModeToggle,
    ColorSwitcher,
    FlagSelector,
  },
  computed: {
    ...mapGetters("theme", {
      marketingTheme: "marketingTheme",
      marketingColor: "marketingColor",
    }),
  },
})
export default class HeaderComponent extends Vue {
  droppedDown: boolean = false;
  currentMarketingTheme: string = "";
  currentMarketingColor: string = "";
  currentPath: string = "";
  mounted() {
    this.currentMarketingTheme = this.$store.state.theme.marketingTheme;
    this.currentMarketingColor = this.$store.state.theme.marketingColor;
  }
  isCurrent(path) {
    return this.$route.path.includes(path);
  }
  get headerColor() {
    const color =
      this.$store.state.theme.marketingTheme === "theme-bg-dark"
        ? "bg-secondary"
        : "bg-secondary";
    return color;
  }
  get headerColorMobile() {
    const color =
      this.$store.state.theme.marketingTheme === "theme-bg-dark"
        ? "bg-secondary"
        : "bg-primary";
    return color;
  }
}
