





































































































































































































































































































































































import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";

// @ts-ignore
import GoogleSignInButton from "vue-google-signin-button-directive";
// @ts-ignore
import { Card, createToken } from "vue-stripe-elements-plus";

import LoadingButton from "@/components/shared/buttons/LoadingButton.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import Modal from "@/components/shared/modals/Modal.vue";
import axios from "axios";
import BillingPeriodToggle from "../../components/marketing/toggles/BillingPeriodToggle.vue";
import Plans from "@/components/marketing/Plans.vue";
import MarketingLogo from "@/components/marketing/MarketingLogo.vue";
import Stripe from "stripe";
import { SubscriptionCouponDto } from "../../application/dtos/master/subscriptions/SubscriptionCouponDto";
import { UserLoginType } from "../../application/enum/master/UserLoginType";
import { UserLoggedResponse } from "../../application/contracts/master/users/UserLoggedResponse";
import { SubscriptionBillingPeriod } from "../../application/enum/master/SubscriptionBillingPeriod";
import { SubscriptionProductDto } from "../../application/dtos/master/subscriptions/SubscriptionProductDto";
import { SubscriptionPriceDto } from "../../application/dtos/master/subscriptions/SubscriptionPriceDto";
import { UserRegisterRequest } from "../../application/contracts/master/users/UserRegisterRequest";

@Component({
  components: {
    BillingPeriodToggle,
    ErrorModal,
    Modal,
    Card,
    Plans,
    MarketingLogo,
    LoadingButton,
  },
  directives: {
    GoogleSignInButton,
  },
})
export default class RegisterComponent extends BaseComponent {
  public requireName: boolean = true;
  public requirePhone: boolean = false;
  public requirePassword: boolean = false;
  public droppedDown: boolean = false;
  public googleClientId: any = "";
  public user = {} as UserRegisterRequest;
  private email: string = "";
  private verifyToken: string = "";
  private stripeKey: string = "";
  private stripeOptions: any = {};
  private cardCompleted: boolean = false;
  private registered: boolean = false;
  private haveCoupon: boolean = false;
  private couponCode: string = "";
  private couponDescription: string = "";
  private coupon: SubscriptionCouponDto | null = null;

  created() {
    if (this.$route.query.e) {
      this.user.email = this.$route.query.e.toString();
    }
    if (this.products.length === 0) {
      this.services.subscriptionProducts.getProducts(false, true);
    }
    if (!this.selectedProduct) {
      this.$store.commit("pricing/select", {
        product: this.products[0],
        billingPeriod: this.$store.state.pricing.billingPeriod,
      });
    }
    // @ts-ignore
    this.googleClientId = process.env.VUE_APP_GOOGLE_CLIENT_ID_OAUTH2.toString();
    // @ts-ignore
    this.stripeKey = process.env.VUE_APP_SUBSCRIPTION_PUBLIC_KEY.toString();
    this.stripeOptions = {
      showCardHolderName: true,
      hidePostalCode: false,
      iconStyle: "solid",
      elements: {
        locale: this.$i18n.locale,
      },
    };
  }
  private mounted() {
    if (this.$route.params.coupon) {
      this.haveCoupon = true;
      this.couponCode = this.$route.params.coupon;
      this.searchCoupon(false);
    } else if (this.$route.query.coupon) {
      this.haveCoupon = true;
      this.couponCode = this.$route.query.coupon.toString();
      this.searchCoupon(false);
    }
  }
  private searchCoupon(showError) {
    if (!this.couponCode) {
      return;
    }
    this.services.subscriptionManager
      .getCoupon(this.couponCode, this.selectedPrice.currency)
      .then((response: SubscriptionCouponDto) => {
        this.coupon = response;
        if (this.coupon && this.coupon.name) {
          this.couponDescription = this.coupon.name.toString();
        }
      })
      .catch((error) => {
        this.couponDescription = this.$t(
          "account.register.invalidCoupon"
        ).toString();
        if (showError) {
          // @ts-ignore
          this.$refs["error-modal"].show(this.$t(error));
        }
      });
  }

  private OnGoogleAuthSuccess(idToken) {
    axios
      .get("https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=" + idToken)
      .then((response) => {
        this.user.firstName = response.data.given_name;
        this.user.lastName = response.data.family_name;
        this.user.email = response.data.email;
        if (!this.user.organization || this.user.organization.trim() === "") {
          // @ts-ignore
          this.$refs["error-modal"].show(
            this.$t("account.register.setTenantName")
          );
        } else {
          this.tryRegister(UserLoginType.Gmail);
        }
      })
      .catch((error) => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      });
  }
  private OnGoogleAuthFail(error) {
    // @ts-ignore
    this.$refs["error-modal"].show(error);
  }
  private tryRegisterWithPassword() {
    this.tryRegister(UserLoginType.Password);
  }
  private tryRegister(loginType: UserLoginType = UserLoginType.Password) {
    this.email = this.user.email;
    this.user.loginType = loginType;
    // @ts-ignore
    this.user.secretSignInKey = process.env.VUE_APP_SECRETSIGNINKEY?.toString();
    if (this.selectedPrice.trialDays === 0 && this.selectedPrice.price > 0) {
      // @ts-ignore
      this.$refs["card-modal"].show(
        this.$t("account.register.providePaymentDetails"),
        this.$t("shared.cancel")
      );
    } else {
      this.register();
    }
  }
  private pay() {
    createToken()
      .then((data) => {
        if (data.error) {
          // @ts-ignore
          this.$refs["error-modal"].show(data.error.message);
        } else {
          this.register(data.token.id);
        }
      })
      .catch((error) => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      })
      .finally(() => {
        // @ts-ignore
        this.$refs["card-modal"].close();
      });
  }
  private register(cardToken: string = "") {
    this.user.selectedSubscription = {
      subscriptionPriceId: this.selectedPrice.id ?? "",
      subscriptionCardToken: cardToken,
      subscriptionCoupon: this.$route.params.coupon,
    };
    // @ts-ignore
    this.$refs.loadingButton.start();
    this.services.authentication
      .register(this.user)
      .then((response: UserLoggedResponse) => {
        this.registered = true;
        this.verifyToken = response.verifyToken;
      })
      .catch((error) => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      })
      .finally(() => {
        // @ts-ignore
        this.$refs.loadingButton.stop();
      });
  }
  // private changeProduct(product) : void {
  //   this.closeDropdown();
  //   this.$store.commit("pricing/select", product);
  // }
  private closeDropdown() {
    this.droppedDown = false;
  }
  private selectedPriceIsOneTimePrice(): boolean {
    return (
      !this.selectedPrice ||
      this.selectedPrice.billingPeriod === SubscriptionBillingPeriod.Once
    );
  }
  get products(): SubscriptionProductDto[] {
    return this.$store.state.pricing.products;
  }
  get verifyURL(): string {
    if (this.verifyToken && this.verifyToken !== "") {
      return "/account/verify?e=" + this.email + "&t=" + this.verifyToken;
    }
    return "";
  }
  get getButtonText(): string {
    return (
      (this.selectedPrice.billingPeriod === SubscriptionBillingPeriod.Once
        ? this.$t("marketing.pricing.pay")
        : this.$t("marketing.pricing.subscribe")) +
      " " +
      this.priceDescription
    );
  }
  get selectedPrice(): SubscriptionPriceDto {
    return (
      this.selectedProduct.prices.find(
        (f) =>
          f.billingPeriod === this.$store.state.pricing.billingPeriod &&
          f.currency === this.$store.state.pricing.currency
      ) ??
      this.selectedProduct?.prices.filter(
        (f) => f.currency === this.$store.state.pricing.currency
      )[0]
    );
  }
  get billingPeriod(): string {
    if (this.selectedPrice.billingPeriod === SubscriptionBillingPeriod.Once) {
      return this.$t("marketing.pricing.once").toString();
    } else {
      return (
        "/ " +
        this.$t(
          "marketing.pricing." +
            SubscriptionBillingPeriod[this.selectedPrice.billingPeriod] +
            "Short"
        )
      );
    }
  }
  get discountedPrice(): number {
    let price = this.selectedPrice.price;
    let couponApplied = "";
    if (this.coupon) {
      if (
        this.coupon.amountOff &&
        this.selectedPrice.currency === this.coupon.currency
      ) {
        couponApplied =
          " -$" +
          this.coupon.amountOff / 100 +
          " " +
          this.coupon.currency.toUpperCase();
        price = price - this.coupon.amountOff / 100;
      } else if (this.coupon.percentOff) {
        couponApplied = " -" + this.coupon.percentOff + "%";
        price = Number(
          (price * ((100 - this.coupon.percentOff) / 100)).toFixed(2)
        );
      }
    }
    return price;
  }

  get priceDescription(): string {
    return "$" + this.discountedPrice + " " + this.billingPeriod;
  }
  get selectedProduct(): SubscriptionProductDto {
    return this.$store.state.pricing.selectedProduct;
  }
}
