












import Vue from "vue";
import Component from "vue-class-component";
import { mapGetters } from "vuex";
// @ts-ignore
import Moon from "@/assets/images/moon.svg";
// @ts-ignore
import Sun from "@/assets/images/sun.svg";

@Component({
  components: { Moon, Sun },
  computed: {
    ...mapGetters("theme", {
      marketingTheme: "marketingTheme",
      marketingColor: "marketingColor",
    }),
  },
})
export default class DarkModeToggleComponent extends Vue {
  toggle() {
    if (this.$store.state.theme.marketingTheme === "theme-bg-dark") {
      this.$store.commit("theme/marketingTheme", "theme-bg-light");
    } else {
      this.$store.commit("theme/marketingTheme", "theme-bg-dark");
    }
  }
}
