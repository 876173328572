
















































import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class ModalComponent extends Vue {
  public title!: string;
  public closeText!: any;
  public showing: boolean = false;
  private errorDescription!: string;
  mounted() {
    this.closeText = this.$t("shared.close").toString();
  }
  public show(title: string = "", closeText: string = "") {
    this.title = title;
    if (closeText !== "") {
      this.closeText = closeText;
    }
    this.showing = true;
  }
  public close() {
    this.showing = false;
  }
}
