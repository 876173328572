

























































































































































































































































































































































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import Selector from "@/components/shared/forms/Selector.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import supportedLocales from "../../../locale/supportedLocales";
import UploadImage from "@/components/shared/forms/UploadImage.vue";
import { UserUpdatePasswordRequest } from "../../../application/contracts/master/users/UserUpdatePasswordRequest";
import { UserUpdateAvatarRequest } from "../../../application/contracts/master/users/UserUpdateAvatarRequest";
import { UserDto } from "../../../application/dtos/master/users/UserDto";
import { UserLoginType } from "../../../application/enum/master/UserLoginType";
import { UserUpdateRequest } from "../../../application/contracts/master/users/UserUpdateRequest";

@Component({
  components: { Selector, SuccessModal, ErrorModal, UploadImage },
})
export default class ProfileComponent extends BaseComponent {
  private profile = {} as UserDto;
  private updatePasswordRequest = {} as UserUpdatePasswordRequest;
  private selectedLocale: string = "";
  private locales: string[] = [];
  private showUploadImage = false;
  created() {
    this.profile.id = this.$store.state.account.user.id;
    this.profile.email = this.$store.state.account.user.email;
    this.profile.firstName = this.$store.state.account.user.firstName;
    this.profile.lastName = this.$store.state.account.user.lastName;
    this.profile.phone = this.$store.state.account.user.phone;
    this.profile.loginType = this.$store.state.account.user.loginType;
  }
  mounted() {
    this.selectedLocale = this.$i18n.locale;
    supportedLocales.forEach((f) => {
      this.locales.push(f.lang);
    });
  }
  canChangePassword() {
    if (this.profile.loginType === UserLoginType.Password) {
      return true;
    }
    return false;
  }
  changedLocale(value) {
    // console.log("changed locale :" + value);
    this.$store.commit("locale/updateLocale", value);
    this.$i18n.locale = value;
    this.$router.go(0);
  }
  updateProfile() {
    const updateRequest: UserUpdateRequest = {
      firstName: this.profile.firstName,
      lastName: this.profile.lastName,
      phone: this.profile.phone,
    };
    this.services.users
      .update(this.profile.id, updateRequest)
      .then((response) => {
        // @ts-ignore
        this.$refs["success-modal"].show(
          this.$t("settings.profile.profileUpdated")
        );
      })
      .catch((error) => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      });
  }
  logout() {
    this.$store.dispatch("auth/logout");
  }
  updatePassword() {
    this.services.users
      .updatePassword(this.updatePasswordRequest)
      .then((response) => {
        // @ts-ignore
        this.$refs["success-modal"].show(
          this.$t("settings.profile.passwordUpdated")
        );
        this.updatePasswordRequest.passwordCurrent = "";
        this.updatePasswordRequest.passwordNew = "";
        this.updatePasswordRequest.passwordConfirm = "";
      })
      .catch((error) => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      });
  }
  deleteAccount() {
    // @ts-ignore
    this.$refs["confirm-modal"].show(this.$t("settings.danger.confirmDelete"));
  }
  confirmDelete() {
    this.services.users
      .deleteMe()
      .then((response) => {
        // ignore
      })
      .catch((error) => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      });
  }
  loadedImage(image) {
    const updateAvatar: UserUpdateAvatarRequest = {
      avatar: image,
    };
    this.services.users
      .updateAvatar(updateAvatar)
      .then((response) => {
        this.showUploadImage = false;
      })
      .catch((error) => {
        console.error("Error: " + JSON.stringify(error));
      });
  }

  get avatar() {
    return this.$store.state.account.user.avatar;
  }
}
