







































































































































































































import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import LoadingButton from "../../components/shared/buttons/LoadingButton.vue";
import GoogleSignInButton from "vue-google-signin-button-directive";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import axios from "axios";
import MarketingLogo from "@/components/marketing/MarketingLogo.vue";
import { UserLoginRequest } from "../../application/contracts/master/users/UserLoginRequest";
import { TenantDto } from "../../application/dtos/master/tenants/TenantDto";
import { UserLoginType } from "../../application/enum/master/UserLoginType";

@Component({
  components: { ErrorModal, MarketingLogo, LoadingButton },
  directives: {
    GoogleSignInButton,
  },
})
export default class LoginComponent extends BaseComponent {
  public googleClientId: any = "";
  public user = {} as UserLoginRequest;
  $refs!: {
    errorModal: HTMLFormElement;
  };
  private tenant: TenantDto | undefined = undefined;
  created() {
    // @ts-ignore
    this.googleClientId = process.env.VUE_APP_GOOGLE_CLIENT_ID_OAUTH2;
  }
  OnGoogleAuthSuccess(idToken) {
    axios
      .get("https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=" + idToken)
      .then((response: any) => {
        this.user.email = response.data.email;
        // @ts-ignore
        this.user.secretSignInKey = process.env.VUE_APP_SECRETSIGNINKEY?.toString();
        this.login(UserLoginType.Gmail);
      });
  }
  OnGoogleAuthFail(error) {
    console.error("Error: " + error);
  }
  login(type: UserLoginType = UserLoginType.Password) {
    // @ts-ignore
    this.$refs.loadingButton.start();
    this.loading = true;
    this.user.loginType = type;
    this.services.authentication
      .login(this.user)
      .then((response) => {
        // this.$router.push("/app/dashboard");
      })
      .catch((error) => {
        this.$refs.errorModal.show(error);
      })
      .finally(() => {
        // @ts-ignore
        this.$refs.loadingButton.stop();
        this.loading = false;
      });
  }
}
