






















































































































































import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import TenantMembers from "@/components/app/settings/tenant/TenantMembers.vue";
import { UserDto } from "../../application/dtos/master/users/UserDto";
import { ColumnType, ValueType } from "../../application/dtos/ColumnType";
import EntityTableList from "@/components/shared/entities/EntityTableList.vue";
import { UserType } from "../../application/enum/master/UserType";

@Component({ components: { TenantMembers, EntityTableList } })
export default class UsersComponent extends BaseComponent {
  private items?: UserDto[] = [];
  mounted() {
    this.reload();
  }
  reload() {
    this.loading = true;
    this.services.users
      .adminGetAll()
      .then((response: UserDto[]) => {
        this.items = response;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  userType(user: UserDto) {
    return UserType[user.type];
  }
  impersonate(user: UserDto) {
    this.services.authentication.impersonate(user.id);
  }
  changePassword(user: UserDto) {
    const password = prompt(
      this.$t("settings.profile.changePassword") + " - " + user.email
    );
    if (!password || password.length < 8) {
      alert("Set a password with 8 characters minimum");
    } else if (user.type === 0) {
      alert("You cannot change password for admin user");
    } else {
      if (
        confirm("[ADMINISTRADOR] Update password for user " + user.email + "?")
      ) {
        this.services.users
          .adminUpdatePassword(user.id, password)
          .then((response) => {
            alert("Updated");
          })
          .catch((error) => {
            alert("Error: " + this.$t(error));
          });
      }
    }
  }
}
