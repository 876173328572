import { render, staticRenderFns } from "./SubscriptionProductOverview.vue?vue&type=template&id=4ed18b2e&"
import script from "./SubscriptionProductOverview.vue?vue&type=script&lang=ts&"
export * from "./SubscriptionProductOverview.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports