
















import Component from "vue-class-component";
import BaseComponent from "../../../../components/shared/BaseComponent.vue";

@Component({
  components: {},
})
export default class PermissionsIndexComponent extends BaseComponent {}
