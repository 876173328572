










































import Component from "vue-class-component";
import { SubscriptionPriceDto } from "../../../../../application/dtos/master/subscriptions/SubscriptionPriceDto";
import { SubscriptionBillingPeriod } from "../../../../../application/enum/master/SubscriptionBillingPeriod";
import BaseComponent from "../../../../../components/shared/BaseComponent.vue";

@Component({
  components: {},
})
export default class TenantSubscriptionPlan extends BaseComponent {
  get myProducts(): SubscriptionPriceDto[] {
    return (this.$store.state.tenant
      .myProducts as SubscriptionPriceDto[]).filter(
      (f) => f.billingPeriod === SubscriptionBillingPeriod.Once
    );
  }
}
