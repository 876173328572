









































































































































































































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import ObjectList from "../../../components/shared/forms/ObjectList.vue";
import { report } from "process";
import TableRowSkeleton from "@/components/shared/skeletons/TableRowSkeleton.vue";
import { SignalService } from "../../../plugins/SignalR";
import { TransactionDto } from "../../../application/dtos/app/transactions/TransactionDto";
import SvgTransactionsEmpty from "../../../assets/img/transactionsEmpty.vue";

@Component({
  components: { ObjectList, TableRowSkeleton, SvgTransactionsEmpty },
})
export default class Transactions extends BaseComponent {
  private items = [] as TransactionDto[];
  created() {
    const self = this;
    this.eventBus.$on("transaction-canceled", () => self.canceled());
    this.eventBus.$on("transaction-deleted", (data) => self.deleted(data));
    this.eventBus.$on("transaction-added", (data) => self.added(data));
    this.eventBus.$on("transaction-saved", (data) => self.saved(data));
  }
  destroy() {
    this.eventBus.$off("transaction-canceled");
    this.eventBus.$off("transaction-deleted");
    this.eventBus.$off("transaction-added");
    this.eventBus.$off("transaction-saved");
  }
  mounted() {
    this.reload();
  }
  async reload() {
    this.items = [];
    this.loading = true;
    this.services.transactions
      .getAll()
      .then((response: TransactionDto[]) => {
        this.items = response;
      })
      .catch((error) => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  deleted(data: TransactionDto) {
    this.items = this.items.filter((f) => f.id !== data.id);
  }
  canceled() {
    // console.log("canceled");
  }
  added(data: TransactionDto) {
    console.log("added data:" + JSON.stringify(data));
    this.items.push(data);
    // SignalService.invoke(
    //   "AddTransaction",
    //   this.$store.state.tenant.current.id,
    //   data
    // );
  }
  saved(data: TransactionDto) {
    const idx = this.items.findIndex((f) => f.id === data.id);
    this.items[idx] = data;
  }
  get orderedItems() {
    if (!this.items) {
      return [];
    }
    return this.items.sort((x, y) => {
      return (x.date > y.date ? -1 : 1) ?? 1;
    });
  }
}
