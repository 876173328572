






































































import Vue from "vue";
import Component from "vue-class-component";
import BaseComponent from "../BaseComponent.vue";
import { Prop } from "vue-property-decorator";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import JsonExcel from "vue-json-excel/JsonExcel.vue";

@Component({
  components: { JsonExcel, ErrorModal },
})
export default class ObjectList extends BaseComponent {
  @Prop() title!: string;
  @Prop() routeNew!: string;
  @Prop({ default: true }) allowDownload!: boolean;

  reload() {
    this.$emit("reload");
  }
  startDownload() {
    // @ts-ignore
    if (!this.$parent.items || this.$parent.items.length === 0) {
      // @ts-ignore
      this.$refs["error-modal"].show(this.$t("shared.noRecords"));
    }
  }
}
