


























































import Vue from "vue";
import Component from "vue-class-component";
import Header from "@/components/marketing/Header.vue";
import { blogService } from "../../services/marketing/BlogService";

@Component({ components: { Header } })
export default class BlogComponent extends Vue {
  posts: any = [];
  async mounted() {
    this.posts = await blogService.getPosts();
  }
}
