















import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import DemoActions from "../../../components/app/subscriptions/DemoActions.vue";
import { mapGetters } from "vuex";

@Component({
  components: { DemoActions },
  computed: {
    ...mapGetters("tenant", {
      activeProduct: "activeProduct",
    }),
    ...mapGetters("tenant", {
      roleName: "roleName",
    }),
  },
})
export default class PermissionsIndexComponent extends BaseComponent {}
