

































































import Component from "vue-class-component";
import BaseComponent from "../../../../../components/shared/BaseComponent.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import Stripe from "stripe";
import { Card, createToken } from "vue-stripe-elements-plus";
import { mapGetters } from "vuex";
import { SubscriptionCardDto } from "../../../../../application/dtos/master/subscriptions/SubscriptionCardDto";

@Component({
  components: { SuccessModal, ErrorModal, Card },
  computed: {
    ...mapGetters("tenant", {
      subscriptionCard: "defaultSubscriptionCard",
    }),
  },
})
export default class TenantSubscriptionPaymentDetails extends BaseComponent {
  private editing: boolean = false;
  private stripeKey!: string;
  private stripeOptions!: any;
  private complete: boolean = false;
  private subscriptionCard!: SubscriptionCardDto;
  created() {
    // @ts-ignore
    this.stripeKey = process.env.VUE_APP_SUBSCRIPTION_PUBLIC_KEY.toString();
    this.stripeOptions = {
      showCardHolderName: true,
      hidePostalCode: false,
      iconStyle: "solid",
      elements: {
        locale: this.$i18n.locale,
      },
    };
  }
  updatePaymentDetails() {
    // @ts-ignore
    this.$refs.loadingButton.start();
    createToken()
      .then((data) => {
        if (data.error) {
          // @ts-ignore
          this.$refs["error-modal"].show(data.error.message);
          // @ts-ignore
          this.$refs.loadingButton.stop();
        } else {
          if (data.token.id && data.token.id !== "") {
            this.updateCardToken(data.token.id);
          }
        }
      })
      .catch((error) => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      })
      .finally(() => {
        // @ts-ignore
        this.$refs.loadingButton.stop();
        this.editing = false;
      });
  }
  updateCardToken(token: string) {
    this.services.subscriptionManager
      .updateCardToken(token)
      .then((response) => {
        this.services.subscriptionManager.getCurrentSubscription();
        // @ts-ignore
        this.$refs["success-modal"].show(
          this.$t("settings.tenant.payment.updated")
        );
      })
      .catch((error) => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      })
      .finally(() => {
        // @ts-ignore
        this.$refs.loadingButton.stop();
        this.editing = false;
      });
  }

  get subscriptionCardLast4() {
    return this.subscriptionCard?.lastFourDigits;
  }
  get subscriptionCardHolder() {
    return this.subscriptionCard?.brand;
  }
  get subscriptionCardExpDesc() {
    return (
      ("0" + this.subscriptionCard?.expiryMonth).slice(-2) +
      " / " +
      this.subscriptionCard?.expiryYear
    );
  }
}
