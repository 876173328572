
















































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import TenantMembers from "@/components/app/settings/tenant/TenantMembers.vue";
import Modal from "@/components/shared/modals/Modal.vue";
import TableRowSkeleton from "@/components/shared/skeletons/TableRowSkeleton.vue";
import { TenantDto } from "../../application/dtos/master/tenants/TenantDto";

@Component({ components: { TenantMembers, Modal, TableRowSkeleton } })
export default class TenantsComponent extends BaseComponent {
  private tenants?: TenantDto[] = [];
  private selectedImage: string = "";
  private selectedImageIsDarkMode: boolean = false;
  mounted() {
    this.reload();
  }
  async reload() {
    this.loading = true;
    this.tenants = [];
    this.services.tenants
      .adminGetAll()
      .then((response: TenantDto[]) => {
        this.tenants = response;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  selectTenant(tenant: TenantDto) {
    this.$router.push("/admin/users/" + tenant.id);
  }
  changeTenant(tenant: TenantDto) {
    this.services.users
      .updateDefaultTenant(tenant.id ?? "")
      .then((response: TenantDto) => {
        this.$store.commit("tenant/current", response);
        this.$router.push("/app");
      });
  }
  selectImage(image, isDarkmode) {
    this.selectedImage = image;
    this.selectedImageIsDarkMode = isDarkmode;
    // @ts-ignore
    this.$refs["image-modal"].show();
  }
  appTheme(tenant: TenantDto) {
    let theme: string = process.env.VUE_APP_THEME?.toString() || "";
    if (tenant.appTheme) {
      theme = tenant.appTheme;
    }
    return theme === "theme-bg-dark" ? "bg-gray-800" : "bg-gray-100";
  }
  appColor(tenant: TenantDto) {
    let color: string = process?.env?.VUE_APP_COLOR?.toString() || "";
    if (tenant.appColor) {
      color = tenant.appColor;
    }
    const current = color?.replace("theme", "");
    return "bg" + current + "-500";
  }
  avatarText(tenant: TenantDto) {
    if (tenant) {
      return tenant.name.substring(0, 2).toUpperCase();
    }
    return "";
  }
  stripeUrl() {
    return process.env.NODE_ENV === "production"
      ? "https://dashboard.stripe.com"
      : "https://dashboard.stripe.com/test";
  }
  customerLink(tenant: TenantDto) {
    return this.stripeUrl() + "/customers/" + tenant.subscriptionCustomerId;
  }
  subscriptionOrProductLink(tenant: TenantDto) {
    if (
      tenant.subscriptionPlanId &&
      tenant.subscriptionPlanId.includes("sub_")
    ) {
      return this.stripeUrl() + "/subscriptions/" + tenant.subscriptionPlanId;
    } else {
      return this.stripeUrl() + "/payments/" + tenant.subscriptionPlanId;
    }
  }
  get orderedItems(): TenantDto[] {
    if (!this.tenants) {
      return [];
    }
    return this.tenants.sort((x, y) => {
      return x.name > y.name ? -1 : 1;
    });
  }
}
