





















































































































import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import BillingPeriodToggle from "../../components/marketing/toggles/BillingPeriodToggle.vue";
import { mapGetters } from "vuex";
import { SubscriptionProductDto } from "../../application/dtos/master/subscriptions/SubscriptionProductDto";
import { SubscriptionBillingPeriod } from "../../application/enum/master/SubscriptionBillingPeriod";
import { SubscriptionPriceDto } from "../../application/dtos/master/subscriptions/SubscriptionPriceDto";

@Component({
  components: { BillingPeriodToggle },
  computed: {
    ...mapGetters("pricing", {
      selectedProductTitle: "selectedProductTitle",
      selectedPrice: "selectedPrice",
      selectedBillingPeriod: "selectedBillingPeriod",
    }),
  },
})
export default class PlansComponent extends BaseComponent {
  public droppedDown: boolean = false;
  created() {
    if (this.$store.state.pricing.products.length === 0) {
      this.services.subscriptionProducts.getProducts(false, true);
    }
  }
  // mounted() {
  //   this.setFromCurrentSubscription();
  // }
  // public setFromCurrentSubscription() {
  //   if (this.subscription) {
  //   }
  // }
  private changeProduct(product, currentPrice) {
    this.closeDropdown();
    this.$store.commit("pricing/select", {
      product,
      billingPeriod: currentPrice.billingPeriod,
    });
  }
  private closeDropdown() {
    this.droppedDown = false;
  }
  private price(product: SubscriptionProductDto): SubscriptionPriceDto {
    return (
      product.prices.find(
        (f) => f.billingPeriod === this.$store.state.pricing.billingPeriod
      ) ?? product.prices[0]
    );
  }
  private billingPeriod(price: SubscriptionPriceDto): string {
    if (price.billingPeriod === SubscriptionBillingPeriod.Once) {
      return this.$t("marketing.pricing.once").toString();
    } else {
      return (
        "/ " +
        this.$t(
          "marketing.pricing." +
            SubscriptionBillingPeriod[price.billingPeriod] +
            "Short"
        )
      );
    }
  }
  private isSelected(
    product: SubscriptionProductDto,
    currentPrice: SubscriptionPriceDto
  ) {
    if (this.selectedProduct?.title === product.title) {
      if (
        currentPrice.billingPeriod === SubscriptionBillingPeriod.Once ||
        (this.$store.state.pricing.billingPeriod ===
          currentPrice.billingPeriod &&
          currentPrice.currency === this.$store.state.pricing.currency)
      ) {
        return true;
      }
    }
    return false;
  }
  private prices(product: SubscriptionProductDto) {
    return product.prices.filter(
      (f) => f.currency === this.$store.state.pricing.currency && f.active
    );
  }
  get products() {
    return (this.$store.state.pricing.products as SubscriptionProductDto[])
      ?.filter((f) => f.active)
      .sort((x, y) => {
        return x.tier > y.tier ? 1 : -1;
      });
  }
  get selectedProduct(): SubscriptionProductDto | undefined {
    return this.$store.state.pricing.selectedProduct as SubscriptionProductDto;
  }
}
